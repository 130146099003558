import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { MainListPage } from "src/components/main-list-page";
import { DataGrid } from "devextreme-react";
import { deleteCampaign } from "src/store/campaigns/saga";
import { useHistory } from "react-router";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";
import { CheckRole } from "src/permissions/checkPermission";
import { useSelector } from "react-redux";
import { userSelector } from "src/store/auth";

const CampaignPage = () => {
  const ref = useRef<DataGrid>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(userSelector);
  const columns = [
    {
      caption: t("Category Name"),
      dataField: "PrivilegeCategoryName",
    },
    {
      caption: t("Supplier Name"),
      dataField: "SupplierName",
    },
    {
      caption: t("Title"),
      dataField: "Title",
    },
    {
      caption: t("Start Date"),
      dataField: "StartDate",
      dataType: "datetime",
    },
    {
      caption: t("End Date"),
      dataField: "EndDate",
      dataType: "datetime",
    },

    {
      caption: t("Coupon Count"),
      dataField: "CouponCount",
    },
    {
      caption: t("Aktif"),
      dataField: "Active",
      dataType: "boolean",
    },
  ];
  return (
    <React.Fragment>
      <MainListPage
        ref={ref}
        columns={columns}
        title="Kampanyalar"
        url="/api/campaigns/devext"
        create={{ type: "url", url: "/campaign/create" }}
        deleteButton={{
          deleteAction: deleteCampaign,
          condition: () => CheckRole(user),
        }}
        edit={{
          url: "/campaign/edit/",
          type: "url",
          condition: () => CheckRole(user),
        }}
        columnButtons={[
          {
            visible: e => e.row.data.ShowTerms && CheckRole(user),
            text: "Şartlar",
            onClick: e => history.push("/privilegeterms/" + e.row.data.Id),
          },
          {
            text: "Kart",
            cssClass: "text-success",
            visible: e =>
              e.row.data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kart,
            onClick: e => history.push("/privilegecards/" + e.row.data.Id),
          },
          {
            text: "Button",
            cssClass: "text-info",
            onClick: e => history.push("/privilegebuttons/" + e.row.data.Id),
          },
          {
            text: "Lokasyon",
            cssClass: "text-warning",
            onClick: e => history.push("/privilege-locations/" + e.row.data.Id),
          },
          {
            visible: e =>
              (e.row.data.PrivilegeUsageTypeId ===
                PrivilegeUsageTypeEnum.Kupon ||
                e.row.data.PrivilegeUsageTypeId ===
                  PrivilegeUsageTypeEnum.QR) &&
              CheckRole(user),
            text: "QR Kod",
            onClick: e => history.push("/privilege-codes/" + e.row.data.Id),
          },
          {
            visible: e =>
              e.row.data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Link &&
              CheckRole(user),
            text: "Link Gösterim",
            onClick: e => history.push("/link-clicks/" + e.row.data.Id),
          },

          {
            text: "Markalar",

            cssClass: "text-secondary",
            onClick: e => history.push("/privilegebrands/" + e.row.data.Id),
          },
        ]}
      />
    </React.Fragment>
  );
};

export default CampaignPage;
